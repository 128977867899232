import { useState } from 'react'
import { useSelector } from 'react-redux'
import FeatureModal from '../../../components/DesignerModals/FeatureModal'
import MembershipLandingAdvert from '../../../components/MembershipLandingAdvert'
import MobileAppPopup from '../../../components/MobileAppPopup'
import WelcomePopup from '../../../components/WelcomePopup/WelcomePopup'
import BlogFooter from '../../../components/blog/Footer'
import HomeAsSeenOn from './HomeAsSeenOn'
import HomeDecorate from './HomeDecorate'
import HomeExplore from './HomeExplore'
import HomeGames from './HomeGames'
import { HomeMyEvent } from './HomeMyEvent'
import HomeReview from './HomeReview'
import HomeShopping from './HomeShopping'
import HomeTemplates from './HomeTemplates'
import HomeTitle from './HomeTitle'

const Home = props => {
  const [featureModalData, setFeatureModalData] = useState(null)
  const isLoggedIn = useSelector(state => state.auth.isAuthenticated)

  return (
    <>
      {featureModalData && <FeatureModal data={featureModalData} setData={setFeatureModalData} />}
      <HomeTitle {...props} />
      <HomeGames {...props} />
      <HomeTemplates {...props} />
      <HomeDecorate setModalData={setFeatureModalData} {...props} />
      <HomeExplore {...props} />
      <HomeMyEvent {...props} />
      <HomeAsSeenOn />
      <MembershipLandingAdvert setFeatureModalData={setFeatureModalData} />
      <HomeShopping />
      <HomeReview />
      <BlogFooter />
      <MobileAppPopup />
      {!isLoggedIn && <WelcomePopup />}
    </>
  )
}

export default Home
